.configCheckbox {
    //
}

.configCheckbox-label {
    //
}

.configCheckbox-input {
    float: none;
    display: inline-block;
    margin-right: 5px !important;
}
