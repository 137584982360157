.hideIt { display: none; }
.ifSignedIn { display: none; }
.ifNotSignedIn { display: none; }

.add {
    color: #6dc520;
    text-decoration: none;
    font-weight: bold;
    padding: 5px 5px 5px 20px;
    background: transparent url(/web3/build/storefront/images/add.png) 0% 50% no-repeat;
}

@media screen {
    #print_frame {
        display: block;
        height: 0;
        padding: 0;
        border: 0;
        margin: 0;
        outline: 0;
        box-shadow: none;
        overflow: hidden;
    }
}

#hostedCheckout .formFields { margin: 0; font-family: "Helvetica", arial, sans-serif; width: 100%; }

#hostedCheckout th { display: block; width: 100%; }
#hostedCheckout td { display: block; }

#hostedCheckout input,
.hosted-fields-container {
    color: #444444;
    box-sizing: border-box;
    font-weight: normal;
    display: block;
    width: 100%;
    height: 35px;
    line-height: 20px;
    font-size: 11px;
    letter-spacing: 1px;
    margin: 0;
    padding: 6px 14px 7px;
    font-family: "Helvetica", sans-serif;
    border: solid 1px #cccccc;
    -webkit-user-select: auto;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -khtml-border-radius: 2px;
    -moz-border-radius: 2px;
    -webkit-border-radius: 2px;
    border-radius: 2px;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    background: #ffffff;
}

.required .hosted-fields-container,
#hostedCheckout .required input { border-color: #cc0000; }
.required th { color: #cc0000; }

#expirationDate,
#cardVerifcationCode { width: 50%; display: block; float: left; box-sizing: border-box; }
#expirationDate { padding-right: 20px; }

.hosted-fields-error-msg {
    visibility: hidden;
    font-size: 12px;
    font-weight: 600;
    color: #cc0000;
    margin: 5px 0 8px;
    padding-top: 3px;
}

.braintree-hosted-fields-invalid + .hosted-fields-error-msg {
    visibility: visible;
}

/*
.braintree-hosted-fields-focused {}
.braintree-hosted-fields-invalid {}
.braintree-hosted-fields-valid {}
*/
.checkoutPaymentButtons { margin-bottom: 15px; font-family: "Helvetica Neue", Arial, sans-serif; font-size: 10px; }
#hosted-form { display: none !important; }

.checkoutPaymentButtons-checkoutButton,
.checkoutPaymentButtons-msg,
.checkoutPaymentButtons-paypalButton {
    float: none;
    display: inline-block;
    vertical-align: middle;
}

.checkoutPaymentButtons-msg {
    margin: 0 10px;
}

.checkoutPaymentButtons-msg_single {
    margin-left: 0;
}

.checkoutPaymentButtons-paypalButton {
    background: transparent url(/web3/build/storefront/images/paypal-logo.png) 0 0 no-repeat;
    text-indent: -9999px;
    width: 100px;
    height: 26px;
    cursor: pointer;
}

#paymentInfoReceivedDiv {
    margin-bottom: 20px;
}

#paymentInfoReceivedDiv th,
#paymentInfoReceivedDiv td {
    padding: 5px 5px 5px 0 !important; /* unfortunately need the important for now. due to smartpress theme code :-( */
}



a.remove {
    color: #a10d25;
    text-decoration: none;
    padding: 5px 5px 5px 20px;
    background: transparent url(/web3/build/storefront/images/delete.png) 0% 50% no-repeat;
}

a.emptyCart {
    text-decoration: none;
    padding: 5px 5px 5px 20px;
    background: transparent url(/web3/build/storefront/images/delete_cart.png) 0% 50% no-repeat;
}

.themeError {
    padding: 3px;
    font-size: 12px;
    background: #cc0000;
    color: #ffffff;
}

div.section {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 2px;
    background: transparent url("/web3/build/storefront/images/tableRowBreak.png") repeat-x scroll top left;
}

table { border-spacing: 0; border-collapse: collapse; }

.approvalNotice {
    color: #cc0000;
    font-weight: bold;
}

a.noImage {
    text-decoration: none;
}

/******************* ACCOUNT CONTROLS AND LINKS ****************/


/* pre-login */
.signInField {
    margin: 0 0 8px;
}

.signInField input.textField {
    width: 120px;
}

.rememberMe {
    font-size: 11px;
}




/************************* ACTION AREA STYLES *******************/
.actionArea {
    padding: 10px 15px;
    margin: 0;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    -moz-border-radius: 12px;
    -khtml-border-radius: 12px;
    -webkit-border-radius: 12px;
    border-radius: 12px;

    -moz-border-radius-topleft: 0;
    -webkit-border-top-left-radius: 0;
    -khtml-border-top-left-radius: 0;
    border-top-left-radius: 0;

    background-color: #ffffff;
}

.actionArea.reverseBg {
    background: #f6f7f9;
}

.actionArea.status {
    padding: 20px 15px;
}

.actionArea ul {
    list-style: none;
    margin: 0;
}

.actionArea ul li {
    padding: 2px 0;
    margin: 0;
}

.actionArea ul li a {
    display: block;
    padding: 2px 4px 2px 18px;
    text-decoration: none;
}

.actionArea ul li a:hover {
    background: #f6f7f9 url(/web3/build/storefront/images/bulletArrow.png) no-repeat scroll 0 1px;
}

.actionArea textarea,
.actionArea textarea.comments {
    width: 280px;
    margin-bottom: 10px;
    font-size: 11px;
}

.actionArea select {
    width: 200px;
    margin: 0 10px 0 0;
}

a.actionEdit {
    padding: 3px 0 3px 20px;
    text-decoration: none;
    font-weight: bold;
    background: transparent url(/web3/build/storefront/images/edit.png) no-repeat scroll top left;
}

.actionArea.withMargin {
    margin-bottom: 20px;
}

.cancelAction {
    padding: 5px 5px 5px 20px;
    margin-left: 20px;
    position: relative;
    color: #5f5f5f;
    font-weight: normal;
    text-transform: none;
    text-decoration: none;
    background: transparent url(/web3/build/storefront/images/cancel.png) 0% 50% no-repeat;
}

a.cancelAction.inActionArea {
    float: right;
    padding: 0 0 5px 17px;
    height: 10px;
}

.newAction {
    padding: 5px 0;
    text-transform: uppercase;
    font-weight: bold;
}

.newAction a {
    font-size: 10px;
}

.newAction table {
    margin: 0;
}

.newAction td {
    padding: 0 6px;
    text-align: center;
    border-left: 2px solid #999999;
}

.newAction td:first-child {
    border-left: none;
}

.activityLog {
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #dfe1e4;
    border-bottom: 1px solid #b2b3b6;
    -moz-border-radius: 4px;
    -khtml-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 4px;
    font-size: 11px;
    background-color: #f6f7f9;
}

.activities {
    height: 450px;
    overflow: auto;
}

.theActivity {
    margin: 0 0 10px;
    border-bottom: 1px solid #e4e6e9;
}

.timestamp {
    padding: 0 5px 5px 20px;
    color: #7f7f7f;
}

.theActivity .subject,
.actionArea .subject {
    padding: 0 5px 10px 20px;
    color: #333333;
    background: transparent url(/web3/build/storefront/images/bulletGreen.png) no-repeat scroll 0 0;
}

.theActivity blockquote {
    margin: 0;
    padding: 0 5px 5px 20px;
    color: #5f5f5f;
    background: transparent url(/web3/build/storefront/images/quote.png) no-repeat scroll 0 0;
}

.theActivity blockquote p,
.theActivity blockquote ol,
.theActivity blockquote ul {
    margin: 0 0 10px;
}

/************************** FORM FIELDS TABLE **************/

table.formFields th,
table.formFields td { vertical-align: top; }

table.formFields th {
    text-align: left;
    font-weight: normal;
    width: 110px;
    padding: 10px 10px 5px 0;
}
table.formFields td { padding: 5px 0; }

table.formFields td input,
table.formFields td select { width: 200px; }
table.formFields td input[type="checkbox"] { width: auto; }

/* for IE */
/* \ */
* html table.formFields td input#rememberMe {
    width: auto;
    border: 0;
}

/*  */
input.required,
textarea.required,
select.required { border-color: #cc0000; }
table.formFields tr.required th { color: #cc0000; }
table.formFields tr.static th { padding: 5px 10px 5px 0; }

table.formFields tr.required td input,
table.formFields tr.required td select,
table.formFields tr.required td textarea { border-color: #cc0000; }
table.formFields tr.short td input { width: 92px; }
table.formFields tr.long td input { width: 350px; }
table.formFields tr.short td select { width: 100px; }
table.formFields tr.double td input { margin-bottom: 2px; }
table.formFields td textarea { width: 200px; font-size: 11px; }
table.formFields tr.long textarea { width: 350px; height: 40px; font-size: 11px; }

table.formFields tr.total th,
table.formFields tr.total td { font-weight: bold; border-top: 1px solid #acacac; }

table.formFields tr.adjustment th,
table.formFields tr.adjustment td { font-style: italic; }

table.formFields tr.adjustedTotal th,
table.formFields tr.adjustedTotal td { font-weight: bold; border-top: 1px solid #acacac; }

.fieldNotes {
    padding: 5px 0 0;
}

table.formFields.static tr th { padding: 5px 10px 5px 0; }

/************************** FORM ELEMENT GLOBALS **************/
input.check {
    border: 0;
    padding: 0;
    background-color: transparent;
}

a.checkbox {
    display: block;
    height: 15px;
    padding: 0 0 5px 25px;
    text-decoration: none;
    background: transparent url(/web3/build/storefront/images/checkboxes.png) no-repeat scroll 0 0;
}

a.checkbox.on {
    background-position: 0 -29px;
}

a.checkbox.small {
    height: 12px;
    padding: 0 0 4px 22px;
    background: transparent url(/web3/build/storefront/images/checkboxesSmall.png) no-repeat scroll 0 1px;
}

a.checkbox.small.on {
    background-position: 0 -21px;
}

a.radio {
    display: block;
    padding: 2px 0 2px 22px;
    background: transparent url(/web3/build/storefront/images/radios3.png) no-repeat scroll 0 2px;
}

a.radio.on {
    background-position: 0 -38px;
}

/************************** BUTTONS **************************/

button {
    padding: 6px 13px;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    border: 1px solid #999999;
    -moz-border-radius: 6px;
    -khtml-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background-color: #65a2e6;
    cursor: pointer;
}

/* for IE */
/* \ */
* html button {
    padding: 4px;
    border: none;
}

/*  */

button.secondary {
    height: 24px;
    padding: 0 13px;
    font-size: 10px;
    text-transform: none;
    background-color: #aeb3bd;
}

/* for IE */
/* \ */
* html button.secondary {
    padding: 0 4px;
    border: none;
}

/*  */

button.secondary.config {
    margin-left: 5px;
}

a.button,
a.button:hover,
a.button:visited {
    float: left;
    padding: 5px 13px;
    border: 1px solid #999999;
    -moz-border-radius: 6px;
    -khtml-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
    text-decoration: none;
    color: #ffffff;
    background-color: #65a2e6;
}

a.button.secondary {
    padding: 3px 13px 2px;
    font-size: 11px;
    text-transform: none;
    background-color: #aeb3bd;
}


a.continueButton,
a.addToCart,
a.continueButton:hover,
a.addToCart:hover {
    float: left;
    padding: 7px 15px 5px;
    color: #ffffff;
    font-size: 12px;
    font-weight: bold;
    border: 1px solid #999999;
    -moz-border-radius: 6px;
    -khtml-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background-color: #65a2e6;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: none;
}

button.cancel {
    background: #cccccc;
}

.uploadAddToCart {
    padding: 20px 0 0;
    height: 40px;
}


/********************** PRINT CALCULATOR WIDGET *****************/
.configValidationMessages {
    padding: 10px 0 0;
}

.generalCalculator h3 {
    margin: 0 0 5px;
}

.optionWrapperOn {
    padding: 0 0 10px;
}
.focus { background-color: #eeeeee; }


.optionWrapperEmpty { display: none; }

label.config,
label.opQuestion {
    display: block;
}

.op {
    padding: 5px;
    background-color: #eeeeee;
}


input.configField.versionName {
    width: 70px;
    margin-right: 3px;
}

.error {
    border-color: #cc0000;
}

.fieldErrorNote {
    color: #cc0000;
    font-size: 11px;
    padding: 1px 0 0 2px;
}
.dimensionX { padding: 0 5px; }

a.togglePreset {
    display: block;
    font-size: 10px;
}

a.addVersion {
    display: block;
    padding: 3px 0 2px 20px;
    font-size: 10px;
    background: transparent url(/web3/build/storefront/images/add.png) 0 2px no-repeat;
}

a.deleteVersion {
    padding: 3px 0 2px 18px;
    margin-left: 5px;
    text-decoration: none;
    color: #cc0000;
    font-size: 10px;
    background: transparent url(/web3/build/storefront/images/delete.png) 0% 50% no-repeat;
}
.focus a.deleteVersion { display: none; }

.version-quantity td { padding: 0 2px 2px 0; }
.version-quantity .noEdit { width: auto; }
.version-quantity label.config { width: auto; }

table.quote {
    border-width: 0;
    border-spacing: 0;
}

table.quote tr {
    border-bottom: 1px solid #e3e4e5;
}

table.quote th {
    padding: 5px 5px 3px 0;
    text-align: left;
}

table.quote td {
    padding: 5px 0 3px;
    text-align: right;
    font-weight: bold;
}

tr.productDiscount {
    color: #cc0000;
}

tr.productPrice.discounted {
    color: #303030;
}


#validationMessagesDiv ul,
#shippingValidationMessagesDiv ul,
#shippingValidationMessagesDiv ul li {
    list-style: none;
}

#shippingValidationMessagesDiv ul {
    padding: 0;
}

#validationMessagesDiv ul li,
#shippingValidationMessagesDiv ul li {
    padding: 0 10px 0 20px;
    margin: 0;
    color: #cc0000;
    background: transparent url(/web3/build/storefront/images/error.png) 0 0 no-repeat;
}

#validationMessagesDiv ul.validationInfoMessage li,
#shippingValidationMessagesDiv ul.validationInfoMessage li { color: #000000; background: transparent url(/web3/build/storefront/images/information.png) 0 0 no-repeat; }

.changeFocusButton { display: inline; }


.op.lf a.checkbox.small {
    margin: 3px 0 1px;
    color: #666666;
}


.noEdit {
    padding: 3px 6px;
    border: 1px solid #cccccc;
}

textarea.additionalTA { width: 90%; }
input.additionalText { width: 90%; }


/******************** ABSTRACT CALCULATOR CHOICES **************/

div.viewChoices a {
    float: left;
    padding: 4px 0 0 19px;
    margin-right: 12px;
    text-decoration: none;
    white-space: nowrap;
    background: transparent url(/web3/build/storefront/images/jobChoices.png) 0 3px no-repeat;
}

div.viewChoices a.viewTemplate {
    background-image: url(/web3/build/storefront/images/view.png);
}

.calculatorChoices {
    max-height: 650px;
    overflow: auto;
}

.jobDetailInfo .calculatorChoices {
    max-height: none;
}

.calculatorChoices .noEdit {
    font-weight: bold;
    background: #f6f7f9;
}



/******************** QUOTE TIPS *******************************/

a.quoteHelp {
    float: right;
    width: 17px;
    height: 15px;
    text-decoration: none;
    text-indent: -9999px;
    white-space: nowrap;
    background: transparent url(/web3/build/storefront/images/helpIcon.png) 0 0 no-repeat;
}

a.quoteHelp.on {
    background-position: 0 -20px;
}

a.quoteHelp:hover {
    background-position: 0 -20px;
}

.quoteTipPanel {
    position: fixed;
    z-index: 1000;
    top: -1000px;
    max-width: 400px;
    width: 90%;
    padding: 5px 15px 15px;
    background: #ffffff;
    border: 1px solid #333333;
    box-shadow: 0 1px 20px rgba(0, 0, 0, 0.27), 8px 10px 20px rgba(246, 247, 249, 0.6);
    -webkit-box-shadow: 0 0 30px rgba(0, 0, 0, 0.27), 8px 10px 20px rgba(246, 247, 249, 0.6);
    -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.27), 8px 10px 20px rgba(246, 247, 249, 0.6);
    overflow: hidden;
}

.closeQuoteTipPanel {
    height: 20px;
    margin-bottom: 5px;
}

.closeQuoteTips {
    float: right;
    width: 14px;
    height: 14px;
    padding: 2px;
    border: 1px solid #acacac;
    -moz-border-radius: 6px;
    -khtml-border-radius: 6px;
    -webkit-border-radius: 6px;
    border-radius: 6px;
    background: transparent url(/web3/build/storefront/images/close.png) 2px 2px no-repeat;
}

.quoteTipContent {
    height: 315px;
    overflow: auto;
    padding: 0 5px;
}

/********************** SHIP CALCULATOR WIDGET *****************/

.shippingCalcInputs label {
    display: block;
    padding: 0;
}

.shippingCalcInputs div {
    padding: 0 0 10px;
}
.shippingCalcInputs div.c-region-holder { padding: 0; }

div.getQuotesButton { padding: 0; }

table.shippingCalcEstimates th {
    padding: 2px 10px 2px 0;
    text-align: left;
}

table.shippingCalcEstimates td {
    padding: 2px 10px 2px 0;
}

a.c-select-service-for-estimate { display: inline-block; padding: 2px 4px; border: 1px solid #dddddd; text-decoration: none; }

/******************** UPLOAD PAGE ITEM SUMMARY *****************/

table.itemSummary {
    width: 100%;
}

table.itemSummary th,
table.itemSummary td {
    padding: 5px;
    background: #f6f7f9 url(/web3/build/storefront/images/tableRowBreak.png) bottom left repeat-x;
}

table.itemSummary th {
    text-align: left;
}

/************************* CART SUMMARY *************************/

#cartMessage {
    display: none;
}

#cartContents {
    padding: 0 0 10px 25px;
    font-weight: bold;
    background: transparent url(/web3/build/storefront/images/cart.png) 0 0 no-repeat;
}

#cartButton {
    padding: 0 0 0 25px;
}


/********************** CART **************************/

#cartPageDetail {
    min-height: 250px;
}

table.cart {
    width: 100%;
    table-layout: fixed;
}
table.cart tr { border-right: 1px solid #e3e4e5; border-left: 1px solid #e3e4e5; }

table.cart th {
    padding: 10px;
    vertical-align: top;
    text-align: left;
    font-size: 0.9em;
    text-transform: uppercase;
    background: #e3e4e5 url(/web3/build/storefront/images/tableRowBreak.png) bottom left repeat-x;
}

table.cart th.skinny,
table.cart td.skinny {
    padding-right: 0;
    padding-left: 0;
}

table.cart td {
    padding: 10px;
    vertical-align: top;
    background: #f6f7f9 url(/web3/build/storefront/images/tableRowBreak.png) bottom left repeat-x;
}

table.cart th.orderItemQty {
    width: 9%;
}

table.cart th.orderItemPrice {
    width: 6.382978723404255%; /* 60/940 */
}

table.cart th.orderItemAddedOn {
    width: 5.851063829787234%; /* 55/940 */
}

table.cart.projectQuote th.orderItemAddedOn {
    width: 15.95744680851064%; /* 150/940 */
}

table.cart th.orderItemAccount,
table.cart th.orderItemAccount-blank {
    width: 6.382978723404255%; /* 60/940 */
    text-transform: none;
}

table.cart th.orderItemControl {
    width: 3.1914893617021274%; /* 30/940 */
}

table.cart th.orderItemActions {
    width: 5.319148936170213%; /* 50/940 */
}

table.cart tr.totals td { padding: 10px; background-color: #ffffff; }
table.cart tr.totals.sub td { border-top: 2px solid #e3e4e5; }
table.cart tr.codeDiscount td { color: #cc0000; }
table.cart tr.totals.savings td { color: #cc0000; font-weight: bold; }
td.cartLabel { font-weight: bold; text-align: right; }

table.cart tr.cartControls,
table.cart tr.cartControls td {
    border: none;
    background: none;
    vertical-align: bottom;
}
table.cart td.code { padding-top: 7px; }
td.code label { display: block; }
td.code input { width: 70px; margin: 0 5px 5px 0; }

td.code button.secondary {
    border-color: #999999;
    background-color: #aeb3bd;
}

table.cart.checkout tr.totalQuote td {
    font-size: 1em;
    font-weight: bold;
    background-color: #ffffff;
}
table.cart.checkout tr.savings { border: none; }

table.cart.checkout tr.savings td {
    padding-bottom: 0;
    color: #cc0000;
    font-weight: bold;
    background: none;
}

.discountItemPrice {
    color: #cc0000;
}

#c-applied-promo-code {
    display: inline-block;
    padding: 5px 5px 5px 0;
    color: #cc0000;
    font-weight: bold;
}
.c-promocode-error { color: #cc0000; font-weight: bold; }

table.c-printable-quote {
    table-layout: auto;
}

table.cart.c-printable-quote td,
table.cart.c-printable-quote th { font-size: 1em; }

a.button.continueShopping,
a.button.continueShopping:visited {
    text-transform: none;
    background-color: #aeb3bd;
}

.continueShoppingLink {
    margin: 0 0 0 10px;
}

.emptyCart {
    font-weight: bold;
}

table.cart.ledgerAccountEdit th.ledgerAccountCol {
    width: 140px;
    text-align: center;
}

table.cart.ledgerAccountEdit th.ledgerAccountCol.code {
    width: 110px;
}

table.cart.ledgerAccountEdit tr.generalOrderAccount td,
tr.generalOrderAccount td input {
    font-weight: bold;
    background-color: #ffffff;
}

th.ledgerAccountSelector {
    width: 150px;
    position: relative;
}

table.cart.ledgerAccountEdit td {
    position: relative;
}

td.laName {
    text-align: right;
}

td.laName input {
    width: 120px;
}

td.laCode input {
    width: 90px;
}

td.laSelector select {
    width: 30px;
    font-size: 10px;
}

.c-cart-item-name { margin-bottom: 5px; font-size: 1.1em; }
.c-cart-job-action { white-space: nowrap; margin-bottom: 3px; font-weight: lighter; }
.c-item-configure { display: inline-block; margin-right: 20px; }
.c-print-quote { font-weight: lighter; }
.c-edit-job-name * { box-sizing: border-box; }
.c-edit-job-name label { display: block; padding: 0; }
.c-edit-job-name div { padding: 0 0 10px; }

.c-edit-job-name input,
.c-edit-job-name textarea { width: 100%; }

.c-cart-item-name--jobName {
    display: block;
    word-wrap: break-word;
}

/********************** TEMPLATE PREVIEWS **************************/
.c-PagingControls {
    margin: 0 0 10px;
    text-align: center;
}

#largePreviewImageContainer {
    margin: 0 0 20px;
    width: 100%;
    border: 1px solid #eeeeee;
}

table.c-template-paging {
    margin: 0 auto 20px;
}
td.c-page-numbers { white-space: nowrap; text-align: center; padding-left: 10px; padding-right: 10px; }

/*********************** CHECKOUT CART **************************/

table.cart.checkout {
    border-spacing: 0;
    border-collapse: collapse;
    max-width: 100%;
    table-layout: fixed;
}

table.cart.checkout th.orderItem { width: 51.72413793103449%; } /* 150/290 */
table.cart.checkout th.orderItemQty { width: 13.793103448275862%; } /* 40/290 */
table.cart.checkout th.orderItemPrice { width: 34.48275862068966%; } /* 100/290 */

.orderItemName-jobName {
    display: block;
    word-wrap: break-word;
}


/********************* CHECKOUT PROCESS *********************/

ol.checkoutProcess {
    float: left;
    width: 100%;
    padding: 0;
    font-weight: bold;
}

ol.checkoutProcess li {
    float: left;
    padding: 10px;
    margin-left: 0;
    list-style-position: inside;
    border-right: 1px solid #cccccc;
    border-left: 1px solid #ffffff;
    background: #eeeeee;
}

ol.checkoutProcess li.current {
    color: #ffffff;
    border-right-color: #333333;
    border-left-color: #aaaaaa;
    background: #666666;
}

ol.checkoutProcess li.current a {
    color: #ffffff;
}

.addressWarning {
    font-weight: bold;
    color: #cc0000;
}

.loginText {
    font-size: 12px;
    font-weight: bold;
}

.divisionChooserLabel {
    margin-top: 10px;
    font-weight: bold;
}

a.printThePage {
    display: block;
    width: 200px;
    padding: 2px 0 5px 25px;
    background: transparent url(/web3/build/storefront/images/printPageIcon.png) 0 0 no-repeat;
}

select.addressSelector {
    width: 200px;
}

.shippingQuote {
    padding: 0 0 20px;
}

.genericContainer.paymentInfo {
    margin-bottom: 20px;
}

tr.selectedService {
    background: #ffffff;
}

label.metafield {
    display: block;
}

#zeroDollarPaymentNote {
    margin: 60px 0;
}

/********************** SHIPPING PAGE ********************/

.blindShipControl {
    margin: 0 0 20px;
}

.shipTo,
.billTo {
    min-height: 143px;
}

.shipTo,
.blindShip,
.billTo {
    margin: 0 0 20px;
}

table.shippingInfo td { padding: 2px 5px 2px 0; }

table.trackingLog {
    border: 1px solid #efefef;
}

table.trackingLog td {
    padding: 3px;
    font-size: 11px;
    border-bottom: 1px solid #efefef;
}

td.shippingQuote div {
    padding: 4px 0 0 40px;
    font-size: 14px;
}

td.shippingQuote div span {
    font-weight: bold;
}

p.addressNote {
    font-weight: bold;
}

a.editOrderInfo {
    padding: 3px 0 3px 20px;
    background: transparent url(/web3/build/storefront/images/edit.png) no-repeat scroll top left;
}

/********************** ADDRESS MANAGEMENT ********************/
div.userAddress {
    margin: 0 0 20px;
}

a.editAddress {
    display: block;
    margin: 5px 0 0;
}

a.addAddress {
    padding: 3px 0 3px 20px;
    margin-left: 10px;
    background: transparent url(/web3/build/storefront/images/add.png) no-repeat scroll 0 0;
}

/********************** PAYMENT ********************/

a.radio.paymentOption {
    display: inline;
    padding-right: 5px;
    margin-right: 20px;
    text-decoration: none;
}

a.editPaymentInfo {
    display: block;
    margin: 5px 0 0;
}

/********************** FILE MANAGEMENT *******************/

.uploadJobName,
.uploadJobNotes {
    margin: 0 0 20px;
}

input.field.jobName {
    width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

textarea.jobNotes {
    width: 100%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    height: 50px;
    font-size: 11px;
}

p.instructions {
    margin: 5px 0;
}

.uploadRadioOptions {
    margin: 0 0 10px;
}

#printFileDiv {
    clear: both;
}
#proofTermsDiv { padding-top: 20px; }

.theFiles {
    float: left;
    width: 100%;
    margin: 0 0 20px;
    font-size: 11px;
}

.fileRow {
    float: left;
    width: 100%;
    margin: 0 0 5px;
    border-bottom: 1px solid #e4e6e9;
    background: #ffffff;
    clear: both;
}

.fileRow.inactive,
.fileRow.inactive a {
    color: #aaaaaa;
}

.fileName,
.fileNote,
.fileDate,
.deactivate,
.removeFile {
    float: left;
    padding: 5px 10px 5px 5px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.fileNote {
    width: 18%;
    background: none;
}

.fileNote.noNote {
    background: none;
}

.fileDate {
    padding-top: 7px;
}

.deactivate {
    padding-top: 7px;
}

.removeFile {
    width: 22%;
    float: left;
    padding-top: 7px;
}

.fileName {
    width: 60%;
}

.fileName.printFile.job {
    width: 42%;
}

.fileDate.printFile.job {
    width: 20%;
}

.deactivate.printFile.job {
    width: 20%;
}

.fileNote.job {
    width: 18%;
    background: none;
}

a.uploadedFile,
div.uploadedFile {
    display: block;
    padding: 2px 0 4px 23px;
    overflow: hidden;
    text-overflow: ellipsis;
    background: transparent url(/web3/build/storefront/images/fileIcon.png) no-repeat scroll 0 0;
}

a.noteLink {
    display: block;
    padding: 2px 0 4px 20px;
    background: transparent url(/web3/build/storefront/images/note.png) no-repeat scroll 0 0;
}

a.noteLink.addNote {
    color: #6dc520;
    background: transparent url(/web3/build/storefront/images/noteAdd.png) no-repeat scroll 0 0;
}

.theNote {
    width: 90%;
    float: left;
    position: relative;
    padding: 5px 20px;
    margin: 0 0 5px 25px;
    -moz-border-radius: 8px;
    -khtml-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    background: #f5fc89 url(/web3/build/storefront/images/note.png) no-repeat scroll 2px 4px;
}

.actionArea.addFileNote {
    position: relative;
    width: 440px;
    margin: 0 0 5px 20px;
}

input.fileNoteInput {
    width: 95%;
    margin-bottom: 10px;
}

table.fileUpload th {
    text-align: left;
    font-weight: normal;
    padding: 2px 4px 2px 0;
}
table.fileUpload td { padding: 2px 4px 2px 0; }


/********************** PROOFING WIDGET *****************/
table.proofFiles { width: 100%; table-layout: fixed; }

.proofFileName {
}

.proofFileDate {
    width: 80px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

.proofFileActiveStatus {
    width: 60px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

table.proofFiles td {
    padding: 5px 10px 5px 5px;
    vertical-align: top;
    background: #ffffff url(/web3/build/storefront/images/tableRowBreak.png) bottom left repeat-x;
}

table.proofFiles tr.inactive td {
    font-style: italic;
    color: #aaaaaa;
    background-color: #f1f2f5;
}

table.proofFiles tr.inactive td a,
table.proofFiles tr.inactive td a:visited { color: #aaaaaa; }

table.proofFiles td a {
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    padding: 0 0 5px 23px;
    text-decoration: underline;
    background: transparent url(/web3/build/storefront/images/viewDocument.png) no-repeat scroll 0 0;
}

.proofStatus {
    margin: 0 0 10px;
    font-weight: bold;
    font-size: 12px;
}

.proofStatus span {
    color: #005891;
}
.approvalButtons { padding: 15px 0 0; }

button.decline,
button.approve {
    margin-right: 10px;
}

button.decline {
    border-color: #999999;
    background: #cccccc;
}

.agreeToTerms {
    margin: 0 0 10px;
    font-weight: bold;
}

.denialReasons {
    margin: 0 0 10px;
}


/********************** ORDERS ***************************/

div.section.orderListBreak {
    float: left;
    width: 100%;
}

.orderHeader {
    float: left;
    padding: 12px 0 0;
    height: 33px;
    font-size: 17px;
    font-weight: bold;
    color: #adadb0;
    text-shadow: rgba(255, 255, 255, 0.9) 0 1px 0;
    border-bottom: 1px solid #c5c6c7;
    background: #f1f3f6 url(/web3/build/storefront/images/orderHeaderBarBg.png) repeat-x 0 0;
}

.orderHeader.on {
    background-image: url(/web3/build/storefront/images/orderHeaderBarBg_on.png);
}

.orderHeader.completed {
    padding: 8px 0 0;
    height: 25px;
    font-size: 14px;
    background-image: none;
}

.orderHeader.completed.on {
    background-image: url(/web3/build/storefront/images/orderHeaderBarBg_complete_on.png);
}

.listOrderNumber {
    float: left;
    width: 180px;
    margin: 0 10px;
}

a.orderNumber {
    display: block;
    text-decoration: underline;
}

.orderAlert {
    float: left;
    width: 280px;
    height: 33px;
}

.orderStatus {
    float: left;
    width: 230px;
    margin: 0 10px;
}

.orderDate {
    float: left;
    width: 210px;
    margin: 0 10px;
}
span.theStatus { color: #5f5f5f; }
span.theDate { color: #5f5f5f; }

.orderAlert span {
    display: block;
    padding: 0 0 0 30px;
    background: transparent url(/web3/build/storefront/images/jobNoticeIcon.png) no-repeat 0 0;
}

.orderDetailWindow {
    float: left;
    padding: 20px 0;
    border-bottom: 1px solid #c5c6c7;
    background: #ffffff url(/web3/build/storefront/images/orderDetailWindowBg.png) repeat-y 0 0;
}

.theOrderDetails {
    float: left;
    width: 520px;
    margin: 0 10px;
}

.theOrderActivities {
    float: left;
    width: 400px;
    margin: 0 10px;
}

.orderDetailSectionTitle {
    float: left;
    width: 110px;
}

.orderDetailSection {
    float: left;
    width: 400px;
    margin: 0 0 20px 10px;
}

.orderDetailSectionTitle,
.activityLogTitle {
    font-size: 14px;
    font-weight: bold;
}

.genericContainer.jobDetails {
    font-weight: bold;
    font-size: 12px;
}
.jobTitle { padding: 0 0 5px; }

.jobTitle a,
.jobTitle a:visited { display: block; text-decoration: underline; }

.jobNumber {
    float: left;
    width: 80px;
    padding: 0 10px 0 0;
}

.jobNumber a {
    display: block;
    padding: 0 0 0 22px;
    background: transparent url(/web3/build/storefront/images/view.png) no-repeat 0 0;
}
.jobQuantity { padding: 0 0 5px 90px; }

a.jobNotice,
a.jobNotice:visited {
    display: block;
    padding: 3px 0 12px 30px;
    color: #cc0000;
    background: transparent url(/web3/build/storefront/images/jobNoticeIcon.png) no-repeat 0 0;
}

p.jobNotice { /* shown on jobs */
    padding: 3px 0 2px 30px;
    margin: 0 0 10px;
    color: #cc0000;
    font-size: 14px;
    font-weight: bold;
    background: transparent url(/web3/build/storefront/images/jobNoticeIcon.png) no-repeat 0 0;
}

a.viewJob {
    float: right;
    width: 21px;
    height: 21px;
    background: transparent url(/web3/build/storefront/images/goArrow.png) no-repeat 0 0;
}

.orderDetailWindow div.section {
    margin: 10px 0;
}

table.stockItemList,
table.orderData { width: 100%; }

table.stockItemList {
    margin: 0;
}

table.stockItemList td {
    padding: 4px 15px 4px 0;
    background: transparent url("/web3/build/storefront/images/tableRowBreak.png") repeat-x scroll bottom left;
}

table.orderData {
    margin: 0;
}

table.orderData th {
    width: 120px;
    padding: 4px 20px 4px 0;
    font-weight: bold;
    text-align: left;
}

table.orderData td {
    padding: 4px 5px 4px 0;
}

table.orderData tr.total th,
table.orderData tr.total td,
table.orderData tr.totalQuote th,
table.orderData tr.totalQuote td {
    border-top: 2px solid #acacac;
    font-weight: bold;
}

table.orderData tr.discountLine th,
table.orderData tr.discountLine td {
    color: #cc0000;
}

table.orderData.pricing td {
    background: transparent url("/web3/build/storefront/images/tableRowBreak.png") repeat-x scroll bottom left;
}
table.orderData.pricing td div.discountItemPrice { float: left; margin-right: 5px; }

table.orderData.pricing tr.savings td {
    color: #cc0000;
    font-weight: bold;
    background: none;
}

.reorder-job {
    padding: 0 0 0 90px;
    font-weight: normal;
}
.c-reorder-section { margin-bottom: 20px; }
.c-reorder-artwork-option { margin-bottom: 10px; }


a.c-print-from-panel { float: right; margin: 0 0 20px 20px; }
a.c-print-from-panel.lefty { float: none; margin: 0 0 20px; }

a.printInvoice {
    display: block;
    margin-top: 10px;
    padding: 2px 0 5px 25px;
    background: transparent url(/web3/build/storefront/images/printPageIcon.png) 0 0 no-repeat;
}

h1.invoiceTitle,
h1.c-quote-headline { width: 100%; clear: both; color: #999999; margin-bottom: 0; }
h1.c-quote-headline { margin: 20px 0; }

/********************** BUTTONS ****************************/

.c-button {
    display: inline-block;
    padding: 6px 12px;
    margin-bottom: 0;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    background-image: none;
    border: 1px solid transparent;
    border-radius: 4px;

    /* color: #333; */
    background-color: #ffffff;
    border-color: #dddddd;
}
.c-button svg { width: 18px; height: 18px; }

.c-radio { margin: 6px 0; padding: 4px; float: none; }
.c-radio + .c-radio { margin-top: -4px; }
.c-active-radio { color: #333333; background-color: #ffffff; }
.c-radio-label { display: inline-block; float: none; min-height: 20px; padding-left: 20px; margin-bottom: 0; cursor: pointer; font-size: 14px; }
.c-radio-label input[type="radio"] { position: absolute; margin: 4px 0 0 -20px; line-height: normal; }

/********************** ORDERS 2 ***************************/

.c-icon { width: 32px; height: 32px; display: inline-block; box-sizing: border-box; vertical-align: middle; margin: 0; float: none; fill: #888888; transition: fill ease-in-out 0.2s; }
.c-file-spinner { display: inline-block; vertical-align: middle; margin: 0 10px 0 0; float: none; }
.c-file-list .c-icon { margin-right: 10px; }
.c-job-alert .c-icon { padding-right: 10px; width: 42px; }
.c-icon.c-alert { fill: #cc0000; }
.c-icon.c-document-add { fill: #71ab47; margin-right: 10px; }
.c-icon.c-quotation { float: left; width: 20px; height: 20px; fill: #eeeeee; vertical-align: top; }
.c-quote-mark .c-icon.c-quotation { float: none; margin-right: 0; }
.c-icon.c-printer { width: 20px; height: 20px; }
.c-icon.c-document { width: 20px; height: 18px; }
.lefty .c-icon.c-printer { width: 42px; height: 32px; padding-right: 10px; }
a:hover .c-icon { fill: #111111; }

.c-icon.c-pencil,
button .c-icon.c-duplicate { width: 20px; height: 20px; }
table.cart td .c-icon { width: 19px; height: 14px; padding-right: 5px; vertical-align: top; }
.c-icon.c-close { width: 24px; height: 24px; }
.c-icon.c-close:hover { fill: #cccccc; }

.c-cart-item-price a {
    white-space: nowrap;
    text-decoration: none;
}

/* adapted from bootstrap */
.c-tab-menu { padding-left: 0; margin: 0; list-style: none; min-width: 350px; border-bottom: 1px solid #dddddd; padding: 0 2%; background: #f7f7f7; }
.c-tab-menu > li { position: relative; display: block; margin-left: 0; width: auto; }
.c-tab-menu > li > a { position: relative; display: block; padding: 10px 13px; }

.c-tab-menu > li > a:hover,
.c-tab-menu > li > a:focus { text-decoration: none; background-color: #ffffff; }
.c-tab-menu > li { float: left; margin-bottom: -1px; cursor: pointer; }
.c-tab-menu > li > a { margin-right: 2px; line-height: 1.42857143; border: 1px solid transparent; border-radius: 4px 4px 0 0; }
.c-tab-menu > li > a:hover { border-color: #eeeeee #eeeeee #dddddd; }

.c-tab-menu > li.active > a,
.c-tab-menu > li.active > a:hover,
.c-tab-menu > li.active > a:focus { color: #555555; cursor: default; background-color: #ffffff; border: 1px solid #dddddd; border-bottom-color: transparent; }

.c-tab-menu:before,
.c-tab-menu:after { display: table; content: " "; }
.c-tab-menu:after { clear: both; }

label.c-input-label { display: block; }
div.c-field-group { margin-bottom: 10px; }

.filter-summary-field { width: 100%; box-sizing: border-box; padding: 8px; border: 1px solid #cccccc; font-size: 1.1em; border-radius-top-right: 0; border-radius-bottom-right: 0; }
.filter-summary-button { float: left; }

#c-order-search { margin-bottom: 20px; clear: both; }

a.c-view-all-orders { display: inline-block; border: 1px solid #dddddd; padding: 10px 20px; }
a.c-view-all-orders svg { width: 18px; height: 18px; margin: 0 5px 0 -5px; }

.c-unified-input { box-sizing: border-box; position: relative; display: table; border-collapse: separate; }
.c-unified-input .c-unified-input-field { box-sizing: border-box; display: table-cell; position: relative; float: left; width: 100%; margin-bottom: 0; border-radius: 4px; border-top-right-radius: 0; border-bottom-right-radius: 0; height: 34px; padding: 6px 12px; font-size: 14px; line-height: 1.4; color: #555555; background-color: #ffffff; background-image: none; border: 1px solid #cccccc; -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); -webkit-transition: border-color ease-in-out 0.15s, -webkit-box-shadow ease-in-out 0.15s; -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; }
.c-unified-input .c-unified-input-button-wrap { box-sizing: border-box; display: table-cell; position: relative; font-size: 0; white-space: nowrap; width: 1%; vertical-align: middle; }
.c-unified-input .c-unified-input-button-wrap .c-unified-input-button { box-sizing: border-box; margin-left: -1px; display: inline-block; position: relative; border-radius: 4px; border-top-left-radius: 0; border-bottom-left-radius: 0; height: 34px; padding: 6px 12px; margin-bottom: 0; font-size: 14px; font-weight: 400; line-height: 1.4; text-align: center; white-space: nowrap; vertical-align: middle; cursor: pointer; user-select: none; background-image: none; border: 1px solid #cccccc; }

/* paging */
.c-search-counts,
.c-paging { width: 100%; box-sizing: border-box; margin-bottom: 20px; }
.c-paging-control-group { position: relative; display: inline-block; vertical-align: middle; }
.c-paging-control-group a.c-paging-control { display: inline-block; position: relative; float: left; padding: 5px 10px; margin-bottom: 0; margin-left: -1px; line-height: 1.4; text-align: center; white-space: nowrap; vertical-align: middle; cursor: pointer; user-select: none; background: #ffffff; border: 1px solid #cccccc; text-decoration: none; }
.c-paging-control-group > a.c-paging-control:first-child { margin-left: 0; border-top-left-radius: 4px; border-bottom-left-radius: 4px; }
.c-paging-control-group > a.c-paging-control:last-child { border-top-right-radius: 4px; border-bottom-right-radius: 4px; }

.c-paging-control-group a.c-paging-control:hover,
.c-paging-control-group a.c-paging-control.current-page { background-color: #efefef; }
.c-paging-control-group a.c-paging-control.end-of-list { color: #eeeeee; }
.c-paging-control-group a.c-paging-control.end-of-list:hover { background-color: #ffffff; }

/*  */

.c-order-container { width: 100%; border: 1px solid #dddddd; padding: 0; margin-bottom: 40px; background: #ffffff; box-sizing: border-box; box-shadow: 0 2px 3px 0 rgba(150, 150, 150, 0.2); }
.c-order-container * { box-sizing: border-box; }
.c-order-headline { padding: 2%; min-width: 350px; font-size: 1.4em; background: #f7f7f7; width: 100%; overflow: hidden; }
.c-order-number { float: left; width: 50%; white-space: nowrap; }
.c-order-level-info-holster { display: inline-block; float: right; width: 50%; white-space: nowrap; text-align: right; }
.c-ordered-on { font-size: 0.85em; }
.c-order-headline span { display: inline-block; margin-left: 2%; color: #999999; font-weight: lighter; }
.tab-pane { background: #ffffff; } /***** CHANGE THIS *****/
.c-order-item-container,
.c-order-shipment-container,
.c-order-payment-container { width: 100%; padding: 2%; border-bottom: 1px solid #dddddd; box-shadow: none; transition: box-shadow 1s; }

.c-order-item-container:last-child,
.c-order-shipment-container:last-child,
.c-order-payment-container:last-child { border-bottom: none; }
.c-order-item-container * { float: none; }
.cancelled-item { color: #aaaaaa; background: #fcfcfc; }
.c-focus-highlight { box-shadow: 0 0 10px 0 #cc0000; }

.c-order-item-number,
.c-order-shipment-number { font-size: 1.2em; color: #bbbbbb; font-weight: lighter; }
a.c-reorder-job { float: right; }

.c-order-item-product-name,
.c-order-item-job-name { font-size: 1.5em; font-weight: bold; }
.c-secondary { color: #999999; font-weight: normal; }

.c-order-details-footer {
    padding: 8px 2%;
    border-top: 1px solid #dddddd;
    background: #f7f7f7;
}

.c-files { margin: 20px 0; }
.c-files-tab-row { display: table; width: 100%; float: none; padding: 0; margin: 0; border-bottom: 1px solid #dddddd; }
.c-files-tab-row:after { display: table; content: " "; clear: both; } /* clearfix */
a.c-files-tab { display: table-cell; float: none; width: 120px; padding: 0; margin: 0; height: 38px; border: 1px solid #dddddd; border-bottom: none; background: #f7f7f7; text-align: center; vertical-align: middle; text-decoration: none; }
.c-job-alert { display: table-cell; float: none; padding: 0 0 0 10px; margin: 0; width: auto; height: 38px; vertical-align: middle; }

.c-job-alert span,
.c-job-alert svg { cursor: pointer; }

.c-job-alert.pending-review,
.c-job-alert.needs-files { color: #cc0000; }

.c-files-holster { display: none; float: none; padding: 2% 0; border: 1px solid #dddddd; border-top: none; }
.c-files-holster.print-files-holster { padding-bottom: 0; }

.c-proof-type { padding: 0 2% 20px; }
.c-file-list { display: table; table-layout: fixed; float: none; width: 100%; margin-bottom: 20px; }
.c-file-list * { box-sizing: border-box; }
.c-file-list.c-print-files-list { margin-bottom: 0; }
.c-file-row { display: table-row; float: none; }
.c-file-row.inactive { color: #bbbbbb; background-color: #f7f7f7; font-style: italic; }
.c-file-row.inactive a { background-color: #fafafa; color: #aaaaaa; }
.c-file-row.inactive .c-icon { fill: #dddddd; }
.c-file-info { display: table-cell; float: none; width: 25%; border-bottom: 1px solid #f7f7f7; padding: 5px 2%; vertical-align: top; }
.c-file-info.c-file-name { width: 50%; }

.c-file-info.c-file-date,
.c-file-info.c-file-status { padding-top: 12px; }
.c-file-list.upload-page .c-file-info.c-file-name { width: 75%; }
.c-file-link { display: block; float: none; max-width: 500px; overflow: hidden; white-space: nowrap; text-overflow: ellipsis; }
.c-file-note-space { display: table; table-layout: fixed; width: 100%; float: none; color: #999999; }
.c-file-note.no-note { font-style: italic; }
.c-file-note-space.can-edit .c-file-note { cursor: pointer; }
.c-file-note-space .c-quote-mark { display: table-cell; width: 35px; padding: 5px 5px 0 0; vertical-align: top; text-align: right; float: none; }
.c-file-note-space .c-note-holder { display: table-cell; width: auto; float: none; }

.c-file-note-space input.c-file-note,
.c-file-note-space div.c-file-note { display: block; height: auto; min-height: 35px; width: 100%; border: 1px solid transparent; padding: 6px 9px; float: none; margin: 0; font-size: 12px; }
.c-file-note-space input.c-file-note { border-radius: 4px; border-color: #dddddd; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }
div.c-file-note { background-color: transparent; transition: background-color 300ms ease-in 100ms; }
div.c-file-note.c-updated { background-color: #ffffcc; }

.c-proof-controls { padding: 0 2%; float: none; }
.c-proof-controls button { display: inline-block; float: none; width: 140px; margin: 0 2% 10px 0; }
.c-proof-controls button .c-icon { width: 20px; height: 20px; margin-right: 2px; white-space: nowrap; }
.c-change-proof-status { margin: 5% 0; }

.c-change-proof-status select,
.c-change-proof-status textarea { width: 100%; box-sizing: border-box; }
.c-change-proof-status div { margin-bottom: 10px; }

/* upload */
.c-file-upload-container { float: none; position: relative; clear: both; }
.c-upload-drop-target { float: none; position: relative; padding: 2%; font-style: italic; color: #999999; cursor: pointer; clear: both; }
.c-upload-drop-target.dragover { background-color: #e3f6dc; transition: background-color 200ms ease-in 100ms; }
.c-upload-drop-target:hover { background-color: #e3f6dc; transition: background-color 200ms ease-in 100ms; }
.upload-page-files .c-upload-drop-target { border: 1px dotted #777777; }

.c-progress-bar {
    float: none;
    height: 25px;
    width: 100%;
    margin: 5px 0;
    border-radius: 5px;
    box-shadow: 0 1px 3px #cccccc inset, 0 1px 0 #dddddd;
}

.c-progress-bar span {
    display: inline-block;
    height: 100%;
    border-radius: 3px;
    background-color: #88d078;
    box-shadow: 0 1px 0 rgba(255, 255, 255, 0.5) inset;
    transition: width 0.4s ease-in-out;

    /* striping */
    background-size: 30px 30px;
    background-image: linear-gradient(135deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
    -webkit-animation: animate-stripes 3s linear infinite;
    -moz-animation: animate-stripes 3s linear infinite;
}
@-webkit-keyframes animate-stripes {
    0% { background-position: 0 0; }
    100% { background-position: 60px 0; }
}
@-moz-keyframes animate-stripes {
    0% { background-position: 0 0; }
    100% { background-position: 60px 0; }
}

table.c-key-value-display { width: 100%; max-width: 500px; margin: 5px 0; border: 0; border-collapse: collapse; }
table.c-key-value-display.strict-50 { table-layout: fixed; }
table.c-key-value-display.strict-50 th { width: 50%; }
table.c-key-value-display.bordered tr { border-bottom: 1px solid #eeeeee; }
table.c-key-value-display th { width: 150px; padding: 6px 5px 6px 0; text-align: left; vertical-align: top; }
table.c-key-value-display td { padding: 6px 5px; vertical-align: top; }

table.c-order-pricing { width: 100%; max-width: 600px; margin: 5px 0; border: 0; border-collapse: collapse; }

table.c-order-pricing td,
table.c-order-pricing th { padding: 8px; }
table.c-order-pricing th { border-bottom: 2px solid #dddddd; text-align: left; }
table.c-order-pricing td { border-top: 1px solid #dddddd; }
table.c-order-pricing td.c-price { text-align: right; }
table.c-order-pricing tr.c-total td { font-weight: bold; background: #f7f7f7; border-top-weight: 2px; }
table.c-order-pricing tr.c-savings td { border: none; }

.c-op-question { margin-top: 5px; font-weight: bold; font-style: italic; }
.c-op-question-answer {  }

.c-sub-answer { font-weight: normal; font-style: italic; }

.c-log-entry { border-bottom: 1px solid #dddddd; padding: 10px 0; transition: background-color 500ms ease-out 200ms; }
.c-timestamp { color: #bbbbbb; font-weight: lighter; }
.c-subject { font-weight: bold; margin-bottom: 5px; }
.c-log-entry blockquote { margin: 5px 0 0 30px; max-width: 600px; padding: 3px 30px 0 0; }
.new-comment { background-color: #f8ffa8; }

.c-post-log-comment { margin-bottom: 20px; }

textarea.c-entry-input,
textarea.c-entry-input:focus { display: block; position: relative; width: 100%; max-width: 600px; margin-bottom: 0; border-radius: 4px; border-bottom-left-radius: 0; border-bottom-right-radius: 0; height: 4em; padding: 6px 12px; font-size: 1em; line-height: 1.42857143; background-color: #ffffff; background-image: none; border: 1px solid #cccccc; box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s; overflow: auto; }
button.c-entry-submit { position: relative; height: auto; max-width: 600px; width: 100%; margin-top: -1px; background-image: none; border-color: #444444; font-size: 1em; border-radius: 4px; border-top-right-radius: 0; border-top-left-radius: 0; }

.c-version-quantity { display: inline-block; padding: 1px 5px; border: 1px solid #aaaaaa; }
.c-total-quantity { padding: 10px 0; }

/***** PAGER *****/

.c-list-paging { display: table; width: 100%; }
.c-result-paging { display: table-row; }
.c-result-counts { display: table-cell; padding: 10px 0; }
.c-paging-utils { display: table-cell; padding: 10px 0; }


/************************ BULK ADD *************************/
.bulkVariantSearch {
    display: inline;
    float: left;
    width: 940px;
    margin: 0 10px 20px;
}

.bulkSearchGroup {
    float: left;
    margin-right: 20px;
}

.bulkChooserContainer {
    display: inline;
    float: left;
    width: 580px;
    margin-left: 10px;
    margin-right: 10px;
}

.bulkStageContainer {
    display: inline;
    float: left;
    width: 350px;
    margin-left: 10px;
}

#stageFloater {
    float: left;
    width: 350px;
}

#cartStage {
    overflow: auto;
}
#readyCount { padding-top: 10px; margin-bottom: 10px; }
.variantPlaceholder { float: left; width: 580px; }

.ui-effects-transfer {
    border: 1px dotted #cc0000;
}

#filterCounts {
    float: left;
    margin: 0;
    padding: 0 10px 10px;
}

.bulkAddButton {
    margin-bottom: 10px;
}

a.bulkExcludeAll,
a.bulkIncludeAll {
    float: right;
    margin: 10px 10px 0 0;
    padding: 3px 20px 3px 5px;
    text-decoration: none;
    background: transparent url(/web3/build/storefront/images/cancel.png) 100% 50% no-repeat;
}

a.bulkIncludeAll {
    background-image: url(/web3/build/storefront/images/bulkAddIncludeAll.png);
}

.aCandidate,
.candidateProductInfo,
.candidateProductControl {
    float: left;
}
.aCandidate { margin-bottom: 10px; background: #eeeeee; }
.candidateProductInfo { width: 400px; }
#cartStage .candidateProductInfo { width: 280px; }

.candidateProductName {
    float: left;
    width: 380px;
    padding: 10px;
}
.candidateName { font-weight: bold; }
.candidateAttributes { font-style: italic; }

.candidateDescription,
.candidateProductDescription { display: none; }

.candidateProductPrice {
    float: left;
    width: 60px;
    padding: 10px;
}

.candidateProductControl {
    /* background: #eec; */
    float: left;
    width: 80px;
    padding: 10px;
}
#cartStage .candidateProductControl { width: 30px; }
#cartStage .candidateProductName { width: 190px; }

#cartStage .candidateProductPrice,
#cartStage a.button.secondary.includeAction,
.candidateProductQuantity { display: none; }

#cartStage .candidateProductQuantity {
    display: block;
    float: left;
    width: 60px;
    padding: 10px 0 10px 10px;
}

#cartStage .candidateProductQuantity .invalid {
    border-color: red;
}

#cartStage .candidateProductError {
    float: left;
    padding: 10px 0 10px 10px;
    color: red;
}

.candidateProductMeta {
    display: none;
}

a.button.secondary.includeAction {
    padding: 7px 30px 7px 10px;
    background: #446c87 url("/web3/build/storefront/images/bulkAddInclude.png") 98% 50% no-repeat;
}

a.excludeAction {
    float: left;
    width: 30px;
    height: 30px;
    background: transparent url("/web3/build/storefront/images/bulkAddExclude.png") 50% 50% no-repeat;
}

#bulkAddChooser .excludeAction,
#cartStage a.button.secondary.includeAction {
    display: none;
}

/************************ REORDER PANEL ********************/
.reorder {
    margin: 5px 0 0;
}

#panelVariantList .aCandidate {
    width: 100%;

    /* max-width: 600px; */
    min-width: 280px;
    margin: 0 10px 10px 0;
    border-bottom: 1px solid #eeeeee;
    background: none;
}

#panelVariantList .aCandidate.ommitted {
    color: #999999;
    background: #efefef;
}

#panelVariantList .aCandidate.backorder {
    color: #cc0000;
}

#panelVariantList .candidateProductInfo {
    width: auto;
}

#panelVariantList .candidateProductQuantity {
    display: block;
    float: left;
    width: 75px;
    padding: 10px;
}

#panelVariantList .candidateProductName {
    width: auto;
}

#panelVariantList .candidateProductControl {
    display: none;
}

.qtyNote { font-style: italic; }

.aCandidate .backordered,
.aCandidate .excluded { display: none; }

.aCandidate.backorder .backordered,
.aCandidate.ommitted .excluded { display: block; }

/************************ USER MESSAGES ********************/

.userMessages {
    position: fixed;
    _position: absolute; /* ie6 will pick this up */
    z-index: 100;
    top: 0;
    left: 0;
    right: 0;
    -webkit-box-shadow: 0 -10px 15px 20px rgba(50, 50, 50, 0.5);
    -moz-box-shadow: 0 -10px 15px 20px rgba(50, 50, 50, 0.5);
    box-shadow: 0 -10px 15px 20px rgba(50, 50, 50, 0.5);
}

.userMessages:before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 10px;
    content: " ";
    background: rgba(0, 0, 0, 0.2);
}

.umBg { background-color: #000000; }
.userMessages.success .umBg { background-color: #2ecc40; }
.userMessages.info .umBg { background-color: #0074d9; }
.userMessages.error .umBg { background-color: #ff4136; }

.um {
    padding: 20px;
    text-align: center;
    font-size: 14px;
    color: #ffffff;
}

.ccValidationError {
    padding: 30px 20px 20px 50px;
    margin-bottom: 20px;
    font-weight: bold;
    color: #111111;
    border: 1px solid #cc0000;
    background: #fedede url(/web3/build/storefront/images/alertError.png) 0 20px no-repeat;
}
.ccValidationError p { margin-bottom: 10px; }

.cometMessages { display: none; }

#userMessageError,
#userMessageInfo,
#userMessageAlert {
    padding: 0 0 2px 20px;
    margin-bottom: 20px;
    background: transparent none 0 0 no-repeat;
}

#userMessageError {
    color: #c70e75;
    background-image: url(/web3/build/storefront/images/error.png);
}

#userMessageAlert {
    color: #333333;
    background-color: #f6f7f9;
    background-image: url(/web3/build/storefront/images/information.png);
}

#userMessageInfo {
    padding: 5px 5px 5px 22px;
    font-weight: bold;
    color: #333333;
    background-color: #f6f7f9;
    background: #f6f7f9 url(/web3/build/storefront/images/success.png) 2px 5px no-repeat;
}

#loginMessages #userMessageError,
#loginMessages #userMessageInfo,
#loginMessages #userMessageAlert {
    margin-bottom: 0;
}

/********************* PANELS ******************/

.panel {
    top: -1000px;
    position: absolute;
    z-index: 70;
    border: 1px solid #444444;
    background-color: #ffffff;
    box-sizing: border-box;
    width: 400px;
    padding: 15px 10px 10px 20px;
    border: 1px solid #444444;
    border-top: 1px solid #acacac;
    border-bottom: 2px solid #222222;
    -moz-border-radius: 8px;
    -khtml-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

.panelContent {
    padding: 15px;
    font-size: 12px;
}

.panel h2 {
    padding-bottom: 10px;
    border-bottom: 1px solid #efefef;
}

.panelButtons {
    padding: 0 15px 15px;
    text-align: center;
}
.panelButtons button.cancel { margin-right: 20px; }

.panel.alert,
.panel.confirm {
    z-index: 75;
    width: 350px;
    padding: 5px;
    border: 1px solid #444444;
    border-top: 1px solid #acacac;
    border-color-bottom: #222222;
    -moz-border-radius: 8px;
    -khtml-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}

.panel.alert h2 {
    color: #cc0000;
}

#savePanelContent { margin-bottom: 10px; }

#savePanel #closeButton a.close {
    float: right;
    background: none;
}

div.panelButton {
    padding: 0 0 20px 120px;
}


.screenOverlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 1px;
    height: 1px;
    background-color: #000000;
}

.panelOverlay {
    width: auto;
    height: auto;
}

.panel-open {
    overflow: hidden;
}

.panel-open .panelWrapper {
    overflow-x: hidden;
    overflow-y: auto;
}

.panelWrapper {
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000;
    -webkit-overflow-scrolling: touch;
    outline: 0;
}

.panelWrapper .panel {
    position: relative;
    width: auto;
    margin: 10px auto;

    /* Override default panel rules... */
    top: auto;
}

/* .panelWrapper.fade .panel {
    transform: translate(0, -25%);
    transition: transform 0.3s ease-out;
}

.panelWrapper.in .panel {
    transform: translate(0, 0);
} */

/********************** KEY/VALUE ********************/
.c-keyValue {
    border-collapse: collapse;
}

.c-keyValue th {
    font-weight: bold;
}

.c-keyValue th,
.c-keyValue td {
    text-align: left;
    padding: 15px 0;
}

.c-keyValue td {
    padding-left: 5px;
}

.c-keyValue tr + tr th,
.c-keyValue tr + tr td {
    border-top: 1px solid #e3e4e5;
}

/********************** C-PANEL ********************/
.c-panel {}

.c-panel-hd {}

.c-panel-bd {
    margin-bottom: 20px;
}

.c-panel-ft {}

/********************** ACTION LIST ********************/
.c-actionList {
    margin: 0;
    padding: 0;
    list-style: none;
}

.c-actionList > * {
    float: left;
    margin: 0;
}

.c-actionList > * + * {
    margin-left: 20px;
}

/* clearfix */
.c-actionList {
    *zoom: 1;
}

.c-actionList:before,
.c-actionList:after {
    display: table;
    content: " ";
}

.c-actionList:after {
    clear: both;
}

/* end clearfix */

/********************** FORM ACTIONS ********************/
.c-formActions {}

.c-formActions-list {
    float: right;
    list-style: none;
    margin: 0;
    padding: 0;
}

.c-formActions-list > * {
    float: left;
}

.c-formActions-list > * + * {
    margin-left: 5px;
}

/*
    Shouldn't have to add '.c-formActions-list > *' to the clearfix list,
    but due to FNE on the client-page CSS, it's necessary to contain
    floated buttons.
*/
/* clearfix */
.c-formActions,
.c-formActions-list > * {
    *zoom: 1;
}

.c-formActions:before,
.c-formActions:after,
.c-formActions-list > *:before,
.c-formActions-list > *:after {
    display: table;
    content: " ";
}

.c-formActions:after,
.c-formActions-list > *:after {
    clear: both;
}

/* end clearfix */

.c-formActions_vert > .c-formActions-list,
.c-formActions_vert > .c-formActions-list > * {
    float: none;
}

.c-formActions_vert > .c-formActions-list > * + * {
    margin: 10px 0 0;
}

/********************** PROJECTS ********************/
.c-project {
    margin-bottom: 40px;
}

.c-project-hd {}

/* clearfix */
.c-project-hd {
    *zoom: 1;
}

.c-project-hd:before,
.c-project-hd:after {
    display: table;
    content: " ";
}

.c-project-hd:after {
    clear: both;
}

/* end clearfix */

.c-project-hd-title {
    float: none;
    width: auto;
    padding-right: 20px;
    overflow: hidden; /* new BFC */
    margin: 0 0 15px 5px;
}

.c-project-hd-actions {
    float: right;
    width: auto;
    padding: 5px 0;
}

.c-new-project {
    margin-top: 70px;
}


/********************** CART - PROJECT **************************/
.c_project_title {
    font-size: 20px;
}

.c_manage_items .project_container {
    padding: 20px;
    background: #eeeeee;
    margin-bottom: 15px;
}

.c_manage_items .project_title {
    font-weight: bold;
    font-size: 16px;
}

.c-projectAction-client_active a {
    color: red;
}

.c-change-clients-info {
    padding-top: 15px;
}

.c-title-editable {
    cursor: pointer;
    display: inline-block;
    width: 100%;
    border: 0;
    padding: 0;
    border: 0;
    border-bottom: 1px solid transparent;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
    background: transparent;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
    -o-appearance: none;
    appearance: none;
    line-height: normal;
}

.c-title-editable:hover {
    border-bottom: 1px dashed #cccccc;
}

.c-title-editable.edit {
    display: none;
    font-style: italic;
    padding-left: 0;
    cursor: default;
}


/********************** SPINNER / LOADING **************************/
.c-loading {
    min-height: 200px;
    padding-top: 152px;
    background: transparent url(/web3/build/storefront/images/spinner.gif) no-repeat center 80px;
}

.c-loading-msg {
    font-size: 12px;
    text-align: center;
}

/********************** Form State **************************/
input.ng-invalid,
input.ng-invalid:focus {
    border-color: red;
    color: red;
}

input[disabled],
button[disabled] {
    opacity: 0.5;
    pointer-events: none;
}

/********************** Angucomplete **************************/
.angucomplete-holder {
    position: relative;
}

.angucomplete-dropdown {
    border-color: #ececec;
    border-width: 1px;
    border-style: solid;
    border-radius: 2px;
    min-width: 300px;
    padding: 6px;
    cursor: pointer;
    z-index: 9999;
    position: absolute;
    top: 20px;
    left: 0;

    /* margin-top: -6px; */
    background-color: #ffffff;

    /* comment out lines below for no scrolling on drop menu */
    overflow-y: auto;
    max-height: 350px;
}

.angucomplete-searching {
    color: #acacac;

    /* font-size: 14px; */
}

.angucomplete-title {
    display: inline-block;
}

.angucomplete-description {
    display: inline-block;
    font-size: 90%;
    font-style: italic;
}

.angucomplete-description:before {
    content: "(";
}

.angucomplete-description:after {
    content: ")";
}

.angucomplete-row {
    padding: 5px;
    color: #000000;
    margin-bottom: 4px;
    clear: both;
}

.angucomplete-selected-row {
    background-color: lightgrey;
    color: #ffffff;
}

.angucomplete-image-holder {
    padding-top: 2px;
    float: left;
    margin-right: 10px;
    margin-left: 5px;
}

.angucomplete-image {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    border-color: #ececec;
    border-style: solid;
    border-width: 1px;
}

.angucomplete-image-default {
    /* Add your own default image here
     background-image: url('/assets/default.png');
    */
    background-position: center;
    background-size: contain;
    height: 34px;
    width: 34px;
}

/********************** Autocomplete **************************/
.c-autocomplete {}

.c-autocomplete-input {
    padding-bottom: 15px;
}

.c-autocomplete-list {
    list-style: none;
    margin: -5px 0 5px -5px;
    padding: 0;
}

.c-autocomplete-list > * {
    display: inline-block;
    margin: 5px;
}

.c-autocompleteChip {
    display: inline-block;
    white-space: nowrap;
    padding: 2px 4px;
    border: 1px solid gray;
    background-color: lightgray;
    border-radius: 3px;
    cursor: default;
}

.c-autocompleteChip-terms {

}

.c-autocompleteChip-terms-term {}

.c-autocompleteChip-remove {
    /* visibility: hidden; */
    width: 18px;
    height: 18px;
    padding: 0;
    font-weight: normal;
    font-size: inherit;
    background-color: transparent;
    text-align: center;
    text-transform: none;
    cursor: pointer;
    border-radius: 4px;
    border: 1px solid;
    color: darkgray;
    text-indent: -9999px;
    position: relative;
}

.c-autocompleteChip-remove:after {
    display: block;
    position: absolute;
    width: 14px;
    height: 13px;
    left: 1px;
    top: 1px;
    content: " ";
    background: url(/web3/build/storefront/images/close.png) no-repeat 0 0;
    opacity: 0.3;
}

.c-autocompleteChip-remove:hover {
    background-color: white;
}

.c-autocompleteChip-remove:hover:after {
    opacity: 0.6;
}

.c-autocompleteChip-hideRemove {}

/* IMPLEMENTATION EXAMPLES: */

.c_manage_sharing .c-autocomplete input {
    width: 300px;
}

.c-sharedOwner {
    background-color: lightyellow;
}

.c-autocompleteChip-hideRemove {
    font-style: italic;
    font-weight: bold;
    font-size: 80%;
    opacity: 0.7;
}

.c-autocompleteChip-hideRemove:before {
    content: "(";
}

.c-autocompleteChip-hideRemove:after {
    content: ")";
}

/********************************** Flexbox Table with Fixed Header ************************/

.fixedHeaderFlexTable {
    float: none;
    display: block;
}

.fixedHeaderFlexTable > thead {
    display: block;
    margin-bottom: 5px;
}

.fixedHeaderFlexTable > tbody {
    height: 300px;
    display: block;
    overflow-y: auto;
    backface-visibility: hidden; /* addresses rendering bug in chrome */
}

.fixedHeaderFlexTable > * > tr {
    display: flex;
    align-items: center;
}

.fixedHeaderFlexTable > * > tr > * {
    flex-grow: 1;
}

/********************************** DEBUG ************************/
#jspDebugDiv,
#pageGlobalsDebugDiv {
    margin: 0 0 0 10px;
}

table.debug td {
    padding: 3px;
    font-size: 10px;
    background-color: #e9eff3;
    border-bottom: 1px solid #ffffff;
}

#jspDebugDiv caption {
    font-size: 11px;
    color: #677b87;
}

#pageLogDiv {
    width: 500px;
    height: 100px;
    overflow: auto;
    background-color: #e9eff3;
    font-size: 9px;
    color: #333333;
}

table.debugObject {
    border: 1px solid #cccccc;
}

table.debugObject td {
    padding: 3px;
    font-size: 10px;
    background-color: #e9eff3;
    border-bottom: 1px solid #ffffff;
    vertical-align: top;
}

table.debugObject td.javatypename {
    font-size: 12px;
    color: #000000;
    vertical-align: top;
    background-color: #b3c4cf;
}

.panel img {
    width: auto;
    float: none;
}

.panel .closeTipPanel,
.panel .shippingLogo {
    float: right;
}

/* Angular animations **/

.animate-new-yellow {
    -webkit-transition: all linear 0.5s;
    transition: all linear 0.5s;
}

.animate-new-yellow.ng-leave {
    display: none;
}

.animate-new-yellow.ng-enter {
    background-color: none;
}

.animate-new-yellow.ng-enter.ng-enter-active {
    background-color: #ffffcc;
}

.animate-new-fadein {
    -webkit-transition: all linear 0.8s;
    transition: all linear 0.8s;
}

.animate-new-fadein.ng-enter {
    opacity: 0;
}

.animate-new-fadein.ng-enter.ng-enter-active {
    opacity: 1;
}

.edocCartPreview {
    width: 100%;
    table-layout: fixed;
}

#largePreviewImageContainer img {
    max-width: 100%;
    image-rendering: crips-edges;
    image-rendering: -webkit-optimize-contrast;
    image-rendering: optimizeQuality;
    image-rendering: high-quality;
}

#c-digital-file-filter {
    display: none;
}
