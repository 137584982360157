body { font-family: helvetica, arial, sans-serif; }
a.print { display: none; }
div.optionWrapperOn { font-size: 11px; }

button.c-change-job-config,
#header-account,
#nav-main,
#menu-all-products,
.thank-you-receipt a,
.orderItemName .viewChoices,
#header-menu #logo,
#footer a {
    display: none !important;
}
