@debug "///////////////////// [\"STOREFRONT\"] CSS TODOs: /////////////////////"; // leave note in the console
// /* Important! @import font file(s) must appear first in this file */
// @import "../../base/css/fonts/proxima-nova";

////////////////////////////////////////////////////////////////////////////////////////////////
// Base
////////////////////////////////////////////////////////////////////////////////////////////////
@import "../../base/css/mixins";

////////////////////////////////////////////////////////////////////////////////////////////////
// Third Party
////////////////////////////////////////////////////////////////////////////////////////////////
@import "../../../../../bower_components/jquery-ui/themes/smoothness/jquery-ui";
@debug "TODO: replaced storefront /javascriptLib/jquery-plugins/ui-1.8.5/smoothness/jquery-ui-1.8.5.custom.css with bower supplied. verify no issues.";
@import "../../../../../bower_components/jquery-ui/themes/smoothness/theme";
@import "../../javascriptLib/jquery-plugins/fancybox/jquery.fancybox-1.3.1";
@import "../../web3/css/colorbox";
@import "third-party/bootstrap";
@import "../../../../../bower_components/intl-tel-input/build/css/intlTelInput";
@import "../../base/css/third-party/intlTelInput.scss";

////////////////////////////////////////////////////////////////////////////////////////////////
// Layout
////////////////////////////////////////////////////////////////////////////////////////////////
@import "global";
@import "drop-down-menu-theme";
@debug "TODO: test moving drop-menu CSS before global. putting in current place for legacy reasons, but should ideally come before global.";
@import "drop-down-menu";

////////////////////////////////////////////////////////////////////////////////////////////////
// Legacy
////////////////////////////////////////////////////////////////////////////////////////////////

////////////////////////////////////////////////////////////////////////////////////////////////
// Modules
////////////////////////////////////////////////////////////////////////////////////////////////
@import "../../web3/js/ng/common/collaterate-info/collaterate-info";
@import "modules/config-checkbox";
@import "modules/has-datepicker";
@import "modules/pagination-local";

.customProductAttributes {
    display: none;
}

@import "../../base/css/modules/wysiwyg";

////////////////////////////////////////////////////////////////////////////////////////////////
// Print Styles (IE9+, if older support is needed, move back to explicit declaration in <head>)
////////////////////////////////////////////////////////////////////////////////////////////////
@media print {
    @import "print";
}
