[pagination-local] {
    padding: 10px 0;

    .grid-100 {
        @include clearfix();

        clear: both;
        width: 100%;
    }

    .grid-50 {
        float: left;
        width: 50%;
    }

    .paging-utils {
        text-align: right;

        select,
        input {
            display: inline-block;
            width: 120px;
            background-color: #eeeeee;
        }

        .page-navigator {
            display: inline-block;
            text-align: right;
        }

        .results-per-page {
            display: inline-block;
            margin-left: 15px;
        }
    }
}
